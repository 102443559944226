import React, { useState, useContext } from "react";
import { getRequestData } from "service/api";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Tabs, Tab } from "react-bootstrap";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { AuthContext } from "contexts/AuthContext";

const AssetModal = ({
  onOpenRequestModal,
  isModalOpen,
  closeModal,
  categories,
  filteredAssets,
  updateSelectedCategory,
  refreshRequestedAssets,
}) => {
  const [selectedRequestAsset, setSelectedRequestAsset] = useState([]);
  const [requestReason, setRequestReason] = useState("");
  const [showNewAssetFields, setShowNewAssetFields] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [assetMake, setAssetMake] = useState("");
  const [assetModel, setAssetModel] = useState("");
  const [assetSpecifications, setAssetSpecifications] = useState("");
  const [activeTab, setActiveTab] = useState("existing");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isRequestSuccessful, setIsRequestSuccessful] = useState(true);
  const { currentUser } = useContext(AuthContext);

  const handleTabChange = (key) => {
    setActiveTab(key);
    setShowNewAssetFields(key === "new");
    if (key === "new") resetNewAssetFields();
  };

  const resetNewAssetFields = () => {
    setAssetMake("");
    setAssetModel("");
    setAssetSpecifications("");
  };

  const resetForm = () => {
    setSelectedRequestAsset([]);
    setRequestReason("");
    setShowNewAssetFields(false);
    setErrorMessage("");
    setSelectedCategory("");
    resetNewAssetFields();
  };

  const closeRequestModal = () => {
    resetForm();
    closeModal();
  };

  const handleRequestAssetSelection = (assetId) => {
    if (!showNewAssetFields) {
      if (selectedRequestAsset.includes(assetId))
        setSelectedRequestAsset(
          selectedRequestAsset.filter((id) => id !== assetId)
        );
      else setSelectedRequestAsset([...selectedRequestAsset, assetId]);
    }
  };

  const handleCategorySelection = (category) => {
    setSelectedCategory(category);
    updateSelectedCategory(category);
  };

  const handleSubmitRequest = async () => {
    if (requestReason.trim() === "") {
      setErrorMessage("Please fill in the 'Reason for Request' field.");
      return;
    }

    if (selectedRequestAsset.length === 0 && !showNewAssetFields) {
      setErrorMessage(
        "Please select an existing asset or provide a new asset."
      );
      return;
    }

    if (
      showNewAssetFields &&
      [assetMake, assetModel, assetSpecifications].some(
        (field) => field.trim() === ""
      )
    ) {
      setErrorMessage("Please fill in all new asset fields.");
      return;
    }

    let requestData = [];
    if (showNewAssetFields) {
      requestData.push({
        user: currentUser.email,
        request_reason: requestReason,
        category: selectedCategory,
        make: assetMake,
        model: assetModel,
        specifications: assetSpecifications,
      });
    } else {
      requestData = selectedRequestAsset.map((assetId) => ({
        user: currentUser.email,
        asset: assetId,
        request_reason: requestReason,
        category: selectedCategory,
      }));
    }

    try {
      await Promise.all(requestData.map((data) => getRequestData(data)));
      setIsRequestSuccessful(true);
    } catch (error) {
      setIsRequestSuccessful(false);
      setErrorMessage(error.response?.data?.detail || "An error occurred.");
      setShowConfirmationModal(true);
      return;
    }

    setErrorMessage("");
    closeRequestModal();
    setShowConfirmationModal(true);
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
    refreshRequestedAssets();
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        toggle={closeRequestModal}
        className="custom-modal"
      >
        <ModalHeader className="modal-header">
          Request Asset
          <button
            className="close-btn"
            type="button"
            style={{
              position: "absolute",
              right: "15px",
              background: "none",
              border: "none",
              fontSize: "40px",
              top: "1px",
            }}
            onClick={closeRequestModal}
          >
            &times;
          </button>
        </ModalHeader>
        <ModalBody className="modal-body">
          <form>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <select
              className="form-control"
              name="category"
              id="category"
              value={selectedCategory}
              onChange={(e) => handleCategorySelection(e.target.value)}
            >
              {selectedCategory ? null : (
                <option value="">Select Category</option>
              )}
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
            <Tabs
              id="controlled-tab-example"
              activeKey={activeTab}
              onSelect={handleTabChange}
              className="mb-3 select-asset-tabs"
            >
              <Tab eventKey="existing" title="AVAILABLE">
                <div className="form-control assets-box">
                  {selectedCategory ? (
                    filteredAssets().length > 0 ? (
                      filteredAssets().map((asset) => (
                        <div className="asset-row" key={asset.id}>
                          <input
                            type="checkbox"
                            value={asset.id}
                            checked={
                              selectedRequestAsset &&
                              selectedRequestAsset.includes(asset.id)
                            }
                            onChange={() =>
                              handleRequestAssetSelection(asset.id)
                            } // Modify this function to handle multi-select
                          />
                          <span>{`${asset.make}/${asset.model}/${asset.ram}/${asset.hard_disk}`}</span>
                        </div>
                      ))
                    ) : (
                      <div>No assets found</div>
                    )
                  ) : (
                    <div>Select a category to display assets</div>
                  )}
                </div>
              </Tab>

              <Tab eventKey="new" title="NEW">
                <div className="new-asset-fields">
                  {showNewAssetFields && (
                    <div className="new-asset-fields">
                      <input
                        type="text"
                        id="make"
                        name="make"
                        className="new-field"
                        value={assetMake}
                        placeholder="Make"
                        onChange={(e) => setAssetMake(e.target.value)}
                      />

                      <input
                        type="text"
                        id="model"
                        name="model"
                        className="new-field"
                        value={assetModel}
                        placeholder="Model Details"
                        onChange={(e) => setAssetModel(e.target.value)}
                      />

                      <input
                        type="text"
                        id="specifications"
                        name="specifications"
                        className="new-field"
                        value={assetSpecifications}
                        placeholder="Specifications"
                        onChange={(e) => setAssetSpecifications(e.target.value)}
                      />
                    </div>
                  )}
                </div>
              </Tab>
            </Tabs>
            <label className="reason-label">Reason for Request</label>
            <textarea
              id="reason"
              className="reason-text"
              value={requestReason}
              onChange={(e) => setRequestReason(e.target.value)}
              placeholder="Describe here"
            ></textarea>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button className="submit" onClick={handleSubmitRequest}>
            SUBMIT
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={showConfirmationModal}
        toggle={closeConfirmationModal}
        className="custom-modal"
      >
        <ModalHeader>Confirmation</ModalHeader>
        <ModalBody className="modal-body-content center-content">
          {isRequestSuccessful ? (
            <>
              <CheckCircleIcon style={{ fontSize: 50, color: "green" }} />
              <p className="confirm-tag">
                Hey, your request has been successfully submitted.
              </p>
            </>
          ) : (
            <>
              <CancelIcon style={{ fontSize: 50, color: "red" }} />
              <p className="confirm-tag">
                Request for this asset already exists by this account.🙃
              </p>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button className="close-btn" onClick={closeConfirmationModal}>
            CLOSE
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AssetModal;
